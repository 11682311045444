import React, { useState } from "react";
import "./Home.css";
import Fade from "react-reveal/Fade";
import { Bounce } from "react-reveal";
import { Link } from "react-scroll";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import Typewriter from "typewriter-effect";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import Navbar from "../navbar/Navbar";
import config from "../../config";
import profile from "../../data/image.jpg";
import {
  GitHub,
  Facebook,
  LinkedIn,
  Twitter,
  WhatsApp,
  Instagram,
} from "@material-ui/icons";

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const particlesInit = async (main) => {
    console.log(main);

    await loadFull(main);
  };

  return (
    <div className="home-wrapper">
      <Particles
        className="particles"
        init={particlesInit}
        options={config.particles}
      />
      <div className="home">
        <div
          className={`greeting${!imageLoaded ? " hide" : ""}`}
          style={{ textAlign: "center" }}
        >
          <Fade bottom distance="40px">
            <img
              className="profile"
              alt=""
              src={profile}
              onLoad={() => setImageLoaded(true)}
            />
            <h1 className="greeting-text">
              Hi, I'm <span className="name">Justin</span>.
              <span className="wave-emoji" role="img" aria-label="waving hand">
                👋
              </span>
            </h1>
            <h1 className="greeting-text">
              <Typewriter
                options={{
                  strings: [
                    "I love developing stuffs.",
                    "I love learning new tech.",
                    "AI enthusiast!",
                  ],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 50,
                  cursor: "|",
                  delay: 100,
                }}
              />
            </h1>
            <Bounce cascade>
              <div className="links">
                <a
                  href="https://www.linkedin.com/in/justinnn07"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn fontSize="large" style={{ color: "white" }} />
                </a>
                <a
                  href="https://api.whatsapp.com/send/?phone=%2B919319275200&text=Text%20with%20Your%20boi%20Justin&app_absent=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsApp fontSize="large" style={{ color: "white" }} />
                </a>
                <a
                  href="https://twitter.com/Justinn771"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter fontSize="large" style={{ color: "white" }} />
                </a>
                <a
                  href="https://www.facebook.com/justin.varghese42"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook fontSize="large" style={{ color: "white" }} />
                </a>
                <a
                  href="https://www.instagram.com/justinnn._07/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram fontSize="large" style={{ color: "white" }} />
                </a>
                <a
                  href="http://github.com/Justinnn07"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GitHub fontSize="large" style={{ color: "white" }} />
                </a>
              </div>
            </Bounce>
            <div className="scroll-down">
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-63}
                duration={500}
                style={{ marginTop: 70 }}
              >
                <ArrowDropDownCircleIcon
                  fontSize="large"
                  style={{ pointerEvents: "fill", cursor: "pointer" }}
                />
              </Link>
            </div>
          </Fade>
        </div>
        <Navbar />
      </div>
    </div>
  );
};

export default Home;
