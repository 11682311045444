import React from "react";
import "./Projects.css";
import { Fade } from "react-reveal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { featured_projects } from "../../data/featured_projects.json";
import Section from "../section/Section";
import FeaturedProject from "../featuredProject/FeaturedProject";

const useStyles = makeStyles((theme) => ({
  moreProjects: {
    "&": {
      margin: "20px auto",
      backgroundColor: "#39b175",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#0be779",
        boxShadow: "none",
      },
    },
    "& > *": {
      color: "white",
      padding: 4,
      fontSize: "15px",
      fontWeight: "600",
    },
  },
}));

const Projects = () => {
  const classes = useStyles();

  return (
    <Section title="Projects">
      <div className="projects-content">
        <ul className="projects-list">
          {featured_projects.map(
            ({ name, link, colour, description, languages }, index) => {
              return (
                <li key={index}>
                  <Fade bottom duration={1000} distance="20px">
                    <FeaturedProject
                      name={name}
                      link={link}
                      description={description}
                      colour={colour}
                      languages={languages}
                    />
                  </Fade>
                </li>
              );
            }
          )}
        </ul>
        <Fade bottom duration={1000} distance="20px">
          <div className="more-projects-wrapper">
            <a
              className="project-link"
              href="https://v1.justinn.me/#portfolio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className={classes.moreProjects}
                type="button"
                variant="contained"
              >
                more projects
              </Button>
            </a>
          </div>
        </Fade>
      </div>
    </Section>
  );
};

export default Projects;
