import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <p className="copyright">
        Made with{" "}
        <span role="img" aria-label="thinking">
          ❤️
        </span>{" "}
        by Justin
      </p>
    </footer>
  );
};

export default Footer;
