import React, { useState } from "react";
import "./ContactForm.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  email: {
    "& > *": {
      marginBottom: theme.spacing(2),
      backgroundColor: "#3b4353",
      color: "white",
      "&:hover": {
        backgroundColor: "#3b4353",
        color: "white",
      },
      "&.Mui-focused": {
        backgroundColor: "#3b4353",
        color: "white",
      },
      "&.MuiFilledInput-underline:before": {
        borderBottom: "2px solid #6f7b9b",
      },
      "&.MuiFilledInput-underline:after": {
        borderBottom: "2px solid #258b9e",
      },
    },
  },
  message: {
    "& > *": {
      marginBottom: theme.spacing(2),
      backgroundColor: "#3b4353",
      color: "white",
      "&:hover": {
        backgroundColor: "#3b4353",
        color: "white",
      },
      "&.Mui-focused": {
        backgroundColor: "#3b4353",
        color: "white",
      },
      "&.MuiFilledInput-underline:before": {
        borderBottom: "2px solid #6f7b9b",
      },
      "&.MuiFilledInput-underline:after": {
        borderBottom: "2px solid #258b9e",
      },
    },
  },
  submit: {
    "&": {
      backgroundColor: "#39b175",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#6de9ab",
        boxShadow: "none",
      },
    },
    "& > *": {
      color: "white",
      fontSize: "15px",
      fontWeight: "600",
    },
  },
}));

const ContactForm = () => {
  const [emailText, setEmailText] = useState("");
  const [messageText, setMessageText] = useState("");

  const classes = useStyles();
  return (
    <div className="contact-form-wrapper">
      <form className="contact-form" method="POST">
        <TextField
          className={classes.email}
          type="email"
          name="email"
          label="Email"
          value={emailText}
          variant="filled"
          onChange={(e) => setEmailText(e.target.value)}
        />
        <TextField
          className={classes.message}
          type="text"
          name="message"
          label="Message"
          value={messageText}
          multiline
          rows="5"
          variant="filled"
          onChange={(e) => setMessageText(e.target.value)}
        />
      </form>
    </div>
  );
};

export default ContactForm;
