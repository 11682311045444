import React from "react";
import "./Contact.css";
import Section from "../section/Section";
import { Bounce } from "react-reveal";
import ContactForm from "../contactForm/ContactForm";
import {
  GitHub,
  Facebook,
  LinkedIn,
  Twitter,
  WhatsApp,
  Instagram,
} from "@material-ui/icons";

const Contact = () => {
  return (
    <Section title="Contact">
      <ContactForm />
      <Bounce cascade>
        <div className="links">
          <a
            href="https://www.linkedin.com/in/justinnn07/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedIn fontSize="large" style={{ color: "white" }} />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=%2B919319275200&text=Text%20with%20Your%20boi%20Justin&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsApp fontSize="large" style={{ color: "white" }} />
          </a>
          <a
            href="https://twitter.com/Justinn771"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter fontSize="large" style={{ color: "white" }} />
          </a>
          <a
            href="https://www.facebook.com/justin.varghese42"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook fontSize="large" style={{ color: "white" }} />
          </a>
          <a
            href="https://www.instagram.com/justinnn._07/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram fontSize="large" style={{ color: "white" }} />
          </a>
          <a
            href="http://github.com/Justinnn07"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHub fontSize="large" style={{ color: "white" }} />
          </a>
        </div>
      </Bounce>
    </Section>
  );
};

export default Contact;
